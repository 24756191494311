<template>
  <div>
    <div class="entry">
      <h3>
        <img
          class="logo"
          src="@/img/logo.png"
          alt="インテリジェントヘルスケア ストレスチェックApp"
        />
      </h3>
      <h3 class="uk-text-background">ストレスチェック受診歴</h3>
      <div class="memadd">
        <table class="table2">
          <thead
            style="
              background: linear-gradient(to top, lightcyan 10%, deepskyblue);
            "
          >
            <tr class="fixed01">
              <th>ストレスチェック<br />開始日</th>
              <th>ストレスチェック<br />終了日</th>
              <th>有償区分</th>
              <th>回答済み/実施対象者</th>
              <th>実施状況</th>
              <th>リンク</th>
            </tr>
          </thead>
          <tbody>
            <template v-if="detail" v-cloak>
              <tr v-for="(data, index) in detail" :key="index">
                <td>{{ data.sc_answer_start_date }}</td>
                <td>{{ data.sc_answer_end_date }}</td>
                <td>{{ ret_sc_group_analysis(data.sc_group_analysis) }}</td>
                <td>
                  {{ answered_num[index] }}人&nbsp;/&nbsp;{{
                    total_targets[index]
                  }}人
                </td>
                <td>{{ ret_sc_status(data.sc_status) }}</td>
                <td>
                  <a
                    v-if="data.sc_status == 8"
                    @click="move_pdf_list(data.sc_id)"
                    class="linkable"
                    >分析結果PDF</a
                  ><template v-else
                    ><span class="linkdisable">分析結果PDF</span></template
                  >
                  <a
                    @click="move_answer_status(data.sc_id)"
                    v-if="data.sc_status != 0"
                    class="linkable"
                    >回答状況</a
                  ><template v-else
                    ><span class="linkdisable">回答状況</span></template
                  >
                </td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
      <div class="uk-margin">
        <button
          type="button"
          name="button"
          @click="$router.back()"
          class="uk-button uk-button-default uk-width-1-3"
        >
          &lt;&nbsp;戻る
        </button>
      </div>
    </div>
  </div>
</template>

<script type="text/javascript">
export default {
  data() {
    return {
      detail: null,
      email: null,
      pw: null,
      total_targets: null,
      answered_num: null,
    };
  },
  mounted() {
    this.$store.state.common.sIsLoading = false;
    document.title = "ストレスチェック履歴";
  },
  computed: {
    ret_sc_group_analysis() {
      return (key) => {
        if (key == 0) {
          return "無料";
        } else if (key == 1) {
          return "有料";
        }
      };
    },
    ret_sc_status() {
      return (key) => {
        if (key == 0) {
          return "依頼未確定";
        } else if (key == 1 || key == 2) {
          return "実施期間待ち";
        } else if (key == 3) {
          return "実施期間中";
        } else if (key == 4) {
          return "実施期間終了";
        } else if (key == 5 || key == 6 || key == 7) {
          return "結果分析中";
        } else if (key == 8) {
          return "依頼完了";
        }
      };
    },
  },
  created() {
    this.$store.state.common.sIsLoading = true;
    this.$store.state.common.sDataLoading = true;
    this.email = this.$store.state.email;
    this.pw = this.$store.state.pass;
    this.get_implementation_datas();
    this.$store.state.common.sDataLoading = false;
  },
  methods: {
    get_implementation_datas() {
      this.$axios
        .post("/client/client_impletation_datas", {
          alias_id: this.$store.state.alias_id,
          email: this.email,
          pw: this.pw,
        })
        .then((response) => {
          console.log(response);
          if (response.data.flg) {
            this.detail = response.data.result;
            this.total_targets = response.data.total_targets;
            this.answered_num = response.data.answered_num;
            console.log(this.detail);
          } else {
            console.log("エラー");
          }
          this.$store.state.common.sDataLoading = false;
        })
        .catch((error) => {
          console.log(error);
          this.$store.state.common.sDataLoading = false;
        });
    },
    move_pdf_list(sc_id) {
      this.$store.state.sc_id = sc_id;
      this.$router.push("/client-pdf_list");
    },
    move_answer_status(sc_id) {
      this.$store.state.sc_id = sc_id;
      this.$router.push("/client-answer_status");
    },
  },
};
</script>
